<template>
    <div>
        <h1>Factuurexport</h1>
        <DataTable :value="invoices.data" :lazy="true" :paginator="true" :rows="10" v-model:filters="filters" ref="dt"
            :totalRecords="invoices.total" :loading="loading" @page="onPage($event)" @sort="onSort($event)"
            @filter="onFilter($event)" filterDisplay="row" responsiveLayout="scroll">
            
            <template #header>
                <div class='row'>
                    <!-- <div class='col-sm-3 text-end'>
                        <input class="form-control" type="search" :placeholder="'Zoek'" id="example-search-input" @change="onFilter()" @input="onInput()" v-model="filters['global'].value" autocomplete="off">
                    </div> -->
                    <div class='col-sm-3'>
                        <label for="start_date" class="col-sm-12 col-form-label">Start datum</label>
                        <input class="form-control" id="start_date" type="date" v-model="start_date" @change="getIndex">
                    </div>
                    <div class='col-sm-3'>
                        <label for="end_date" class="col-sm-12 col-form-label">Eind datum</label>
                        <input class="form-control" id="end_date" type="date" v-model="end_date" @change="getIndex">
                    </div>
                    <div class='col-sm-3'>
                        <label for="download" class="col-sm-12 col-form-label">&nbsp;</label>
                        <input class="btn btn-primary" id="download" type="button" @click="download" value="Download excel">
                    </div>
                </div>
            </template>

            <Column field="invoice_number" header="Factuurnummer" :sortable="true"></Column>
            <Column field="date" header="Factuurdatum" :sortable="true">
                <template #body="slotProps">
                    {{formatDate(slotProps.data.date, 'shortDate')}}
                </template>
            </Column>
            <Column field="price" header="Factuurbedrag">
                <template #body="slotProps">
                    € {{new Intl.NumberFormat('en', {useGrouping: false, minimumFractionDigits: 2, maximumFractionDigits: 2}).format(parseFloat(slotProps.data.price))}}
                </template>
            </Column>
            <Column field="dossier.source_charge_contact" header="Doorbelasten naar Client">
                <template #body="slotProps">
                    {{slotProps.data.dossier && slotProps.data.dossier.source_charge_contact == 1 ? "Ja" : "Nee"}}
                </template>
            </Column>
            <Column field="last_name" header="Naam">
                <template #body="slotProps">
                    {{slotProps.data.initials}} {{slotProps.data.prefix}} {{slotProps.data.last_name}}
                </template>
            </Column>
            <Column field="street" header="Adres">
                <template #body="slotProps">
                    {{slotProps.data.street}} {{slotProps.data.house_number}}
                </template>
            </Column>
            <Column field="postal_code" header="Postcode"></Column>
            <Column field="city" header="Plaats"></Column>

            <Column field="email" header="E-mailadres" :sortable="true"></Column>
        </DataTable>
    </div>
</template>

<script>
    import invoiceservice from '@/services/admin/InvoiceService'
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import InputText from 'primevue/inputtext';

    export default {
        name: 'adminInvoices',
        components: {
            DataTable,
            Column
        },
        data() {
            return {
                invoices: {
                    'data': [],
                    'total': 0
                },
                filters: {
                    'global': {
                        value: '',
                        matchMode: 'contains'
                    },
                    'email': {
                        value: '',
                        matchMode: 'contains'
                    },
                },
                loading: true,
                lazyParams: {
                    filters: {}
                },
                page: 1,
                start_date: null,
                end_date: null
            }
        },
        methods: {
            getIndex() {
                this.loading = true;
                invoiceservice.index(this.page, {
                    lazyEvent: JSON.stringify(this.lazyParams)
                }, this.start_date, this.end_date).then(response => {
                    this.invoices = response.data
                    this.loading = false;
                }).catch(error => {
                    console.log('error', error) // create notification-toaster for user error
                })
            },
            download() {
                invoiceservice.download(this.start_date, this.end_date).then(response => {
                    
                    const anchor = document.createElement('a');
                    anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
                    anchor.target = '_blank';
                    anchor.download = `${this.start_date ? this.start_date : 'facturen'} - ${this.end_date ? this.end_date : 'appletree'}.csv`;
                    anchor.click();

                }).catch(error => {
                    console.log('error', error) // create notification-toaster for user error
                })
            },


            
            onPage(event) {
                this.page = event.page + 1
                this.getIndex();
            },
            onSort(event) {
                this.lazyParams = event;
                this.getIndex();
            },
            onInput() {
                if(this.filters['global'].value == '') {
                    this.onFilter();
                }
            },
            onFilter() {
                this.lazyParams.filters = this.filters;
                this.getIndex();
            }
        },
        mounted() {
            this.lazyParams = {
                first: 0,
                rows: this.$refs.dt.rows,
                sortField: null,
                sortOrder: null,
                filters: this.filters
            };
            this.getIndex()
        }
    }
</script>