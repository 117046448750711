import http from '@/http-common'

const URL = '/api/admin/invoice'

class InvoiceService {
    index(page, params, startDate, endDate) {
      var queryParams = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
      if (startDate) {
        queryParams += `&start_date=${startDate}`;
      }
      if (endDate) {
        queryParams += `&end_date=${endDate}`;
      }
      return http.get(`${URL}?page=${page}&${queryParams}`)
    }
    download(startDate, endDate) {
      var queryParams = "";
      if (startDate) {
        queryParams += `start_date=${startDate}&`;
      }
      if (endDate) {
        queryParams += `&end_date=${endDate}`;
      }
      return http.get(`${URL}/download?${queryParams}`)
    }
}

export default new InvoiceService()